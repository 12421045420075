@tailwind base;
@tailwind components;
@tailwind utilities;

.DayPickerInput{
    @apply w-full;
}

strong{
    @apply font-bold;
}

@layer base {
    [data-theme="zorgapp"] {
        --prgrmz-color-system-50: 252, 231, 239;
        --prgrmz-color-system-100: 248, 206, 223;
        --prgrmz-color-system-200: 242, 157, 191;
        --prgrmz-color-system-300: 235, 108, 159;
        --prgrmz-color-system-400: 229, 59, 127;
        --prgrmz-color-system-500: 222, 10, 95;
        --prgrmz-color-system-600: 178, 8, 76;
        --prgrmz-color-system-700: 133, 6, 57;
        --prgrmz-color-system-800: 89, 4, 38;
        --prgrmz-color-system-900: 44, 2, 19;
    }

    [data-theme="default"] {
        --prgrmz-color-system-50: 238, 242, 255;
        --prgrmz-color-system-100: 224, 231, 255;
        --prgrmz-color-system-200: 199, 210, 254;
        --prgrmz-color-system-300: 165, 180, 252;
        --prgrmz-color-system-400: 129, 140, 248;
        --prgrmz-color-system-500: 99, 102, 241;
        --prgrmz-color-system-600: 79, 70, 229;
        --prgrmz-color-system-700: 67, 56, 202;
        --prgrmz-color-system-800: 55, 48, 163;
        --prgrmz-color-system-900: 49, 46, 129;
    }
}

.planning-deploy-state-1{
    @apply bg-green-100 text-green-700;
}

.planning-deploy-state-2{
    @apply bg-yellow-100 text-yellow-700;
}

.planning-deploy-state-3{
    @apply bg-blue-100 text-blue-700;
}

.planning-deploy-state-4{
    @apply bg-red-100 text-red-700;
}

.planning-deploy-state-5{
    @apply bg-red-200 text-red-800;
}

.planning-deploy-state-6{
    @apply bg-gray-700 text-white;
}

.link{
    @apply cursor-pointer text-system-600 hover:text-system-500;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.planningsitem{
    @apply bg-red-100 border border-red-200 text-red-700 text-xs px-2 py-1;
}

.planningsitem-1{
    @apply bg-green-100 border-green-200 text-green-700;
}


.planningsitem-3{
    @apply bg-yellow-100 border-yellow-200 text-yellow-700;
}
.planningsitem-4{
    @apply bg-yellow-100 border-yellow-200 text-yellow-700;
}


.repeater{
    @apply flex flex-col;
}

.repeater .header{
    @apply bg-gray-100 border border-gray-200 font-bold px-3 py-2 flex flex-col sm:flex-row gap-3 text-sm;
}

.repeater .row {
    @apply odd:bg-gray-50 px-3 py-2 flex flex-col sm:flex-row gap-3 text-sm;
}

.repeater .clickable{
    @apply hover:bg-system-50 cursor-pointer;
}